import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Container} from 'semantic-ui-react';
import App from './App';
import Header from './components/Header';
import Footer from './components/Footer';
import {UserProvider} from './context/User';
import {ToastContainer} from 'react-toastify';
import {StatusProvider} from './context/Status';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StatusProvider>
            <UserProvider>
                <BrowserRouter>
                    <Header/>
                    <Container className={'main-content'}>
                        <App/>
                    </Container>
                    <ToastContainer/>
                    <Footer/>
                </BrowserRouter>
            </UserProvider>
        </StatusProvider>
    </React.StrictMode>
);
