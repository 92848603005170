import {showError} from './utils';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : '';

export const API = axios.create({
    baseURL: baseUrl,
});

API.interceptors.response.use((response) => response, (error) => {
    showError(error);
});