import React, {useEffect, useState, useCallback} from 'react';
import {Button, Divider, Form, Grid, Header, Modal} from 'semantic-ui-react';
import {API, removeTrailingSlash, showError} from '../helpers';

const SystemSetting = () => {
    let [inputs, setInputs] = useState({
        PasswordLoginEnabled: '',
        PasswordRegisterEnabled: '',
        EmailVerificationEnabled: '',

        SMTPServer: '',
        SMTPPort: '',
        SMTPAccount: '',
        SMTPFrom: '',
        SMTPToken: '',
        ServerAddress: '',

        TurnstileCheckEnabled: '',
        TurnstileSiteKey: '',
        TurnstileSecretKey: '',
        RegisterEnabled: '',
        EmailDomainRestrictionEnabled: '',
        EmailDomainWhitelist: ''
    });

    const [originInputs, setOriginInputs] = useState({});
    let [loading, setLoading] = useState(false);
    const [EmailDomainWhitelist, setEmailDomainWhitelist] = useState([]);
    const [restrictedDomainInput, setRestrictedDomainInput] = useState('');
    const [showPasswordWarningModal, setShowPasswordWarningModal] = useState(false);

    const getOptions = useCallback(async () => {
        const res = await API.get('/api/option/');
        const {success, message, data} = res.data;
        if (success) {
            let newInputs = {};
            data.forEach((item) => {
                newInputs[item.key] = item.value;
            });
            setInputs({
                ...newInputs,
                EmailDomainWhitelist: newInputs.EmailDomainWhitelist.split(',')
            });
            setOriginInputs(newInputs);

            setEmailDomainWhitelist(newInputs.EmailDomainWhitelist.split(',').map((item) => {
                return {key: item, text: item, value: item};
            }));
        } else {
            showError(message);
        }
    }, []);

    useEffect(() => {
        getOptions().then();
    }, [getOptions]);

    const updateOption = async (key, value) => {
        setLoading(true);
        switch (key) {
            case 'PasswordLoginEnabled':
            case 'PasswordRegisterEnabled':
            case 'EmailVerificationEnabled':
            case 'TurnstileCheckEnabled':
            case 'EmailDomainRestrictionEnabled':
            case 'RegisterEnabled':
                value = inputs[key] === 'true' ? 'false' : 'true';
                break;
            default:
                break;
        }
        const res = await API.put('/api/option/', {
            key,
            value
        });
        const {success, message} = res.data;
        if (success) {
            if (key === 'EmailDomainWhitelist') {
                value = value.split(',');
            }
            setInputs((inputs) => ({
                ...inputs, [key]: value
            }));
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const handleInputChange = async (e, {name, value}) => {
        if (name === 'PasswordLoginEnabled' && inputs[name] === 'true') {
            // block disabling password login
            setShowPasswordWarningModal(true);
            return;
        }
        if (
            name.startsWith('SMTP') ||
            name === 'ServerAddress' ||
            name === 'TurnstileSiteKey' ||
            name === 'TurnstileSecretKey' ||
            name === 'EmailDomainWhitelist'
        ) {
            setInputs((inputs) => ({...inputs, [name]: value}));
        } else {
            await updateOption(name, value);
        }
    };

    const submitServerAddress = async () => {
        const ServerAddress = removeTrailingSlash(inputs.ServerAddress);
        await updateOption('ServerAddress', ServerAddress);
    };

    const submitSMTP = async () => {
        if (originInputs['SMTPServer'] !== inputs.SMTPServer) {
            await updateOption('SMTPServer', inputs.SMTPServer);
        }
        if (originInputs['SMTPAccount'] !== inputs.SMTPAccount) {
            await updateOption('SMTPAccount', inputs.SMTPAccount);
        }
        if (originInputs['SMTPFrom'] !== inputs.SMTPFrom) {
            await updateOption('SMTPFrom', inputs.SMTPFrom);
        }
        if (
            originInputs['SMTPPort'] !== inputs.SMTPPort &&
            inputs.SMTPPort !== ''
        ) {
            await updateOption('SMTPPort', inputs.SMTPPort);
        }
        if (
            originInputs['SMTPToken'] !== inputs.SMTPToken &&
            inputs.SMTPToken !== ''
        ) {
            await updateOption('SMTPToken', inputs.SMTPToken);
        }
    };


    const submitEmailDomainWhitelist = async () => {
        if (
            originInputs['EmailDomainWhitelist'] !== inputs.EmailDomainWhitelist.join(',') &&
            inputs.SMTPToken !== ''
        ) {
            await updateOption('EmailDomainWhitelist', inputs.EmailDomainWhitelist.join(','));
        }
    };

    const submitTurnstile = async () => {
        if (originInputs['TurnstileSiteKey'] !== inputs.TurnstileSiteKey) {
            await updateOption('TurnstileSiteKey', inputs.TurnstileSiteKey);
        }
        if (
            originInputs['TurnstileSecretKey'] !== inputs.TurnstileSecretKey &&
            inputs.TurnstileSecretKey !== ''
        ) {
            await updateOption('TurnstileSecretKey', inputs.TurnstileSecretKey);
        }
    };

    const submitNewRestrictedDomain = () => {
        const localDomainList = inputs.EmailDomainWhitelist;
        if (restrictedDomainInput !== '' && !localDomainList.includes(restrictedDomainInput)) {
            setRestrictedDomainInput('');
            setInputs({
                ...inputs,
                EmailDomainWhitelist: [...localDomainList, restrictedDomainInput],
            });
            setEmailDomainWhitelist([...EmailDomainWhitelist, {
                key: restrictedDomainInput,
                text: restrictedDomainInput,
                value: restrictedDomainInput,
            }]);
        }
    }

    return (
        <Grid columns={1}>
            <Grid.Column>
                <Form loading={loading}>
                    <Header as='h3'>General Settings</Header>
                    <Form.Group widths='equal'>
                        <Form.Input
                            label='Server Address'
                            placeholder='For example：https://yourdomain.com'
                            value={inputs.ServerAddress}
                            name='ServerAddress'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitServerAddress}>
                        Update Server Address
                    </Form.Button>
                    <Divider/>
                    <Header as='h3'>Configure Login/Registration</Header>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.PasswordLoginEnabled === 'true'}
                            label='Allow login via password'
                            name='PasswordLoginEnabled'
                            onChange={handleInputChange}
                        />
                        {
                            showPasswordWarningModal &&
                            <Modal
                                open={showPasswordWarningModal}
                                onClose={() => setShowPasswordWarningModal(false)}
                                size={'tiny'}
                                style={{maxWidth: '450px'}}
                            >
                                <Modal.Header>Warning</Modal.Header>
                                <Modal.Content>
                                    <p>Canceling password login will cause all users (including administrators) who have not bound other login methods to be unable to log in via password, confirm
                                        cancel?</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => setShowPasswordWarningModal(false)}>Cancel</Button>
                                    <Button
                                        color='yellow'
                                        onClick={async () => {
                                            setShowPasswordWarningModal(false);
                                            await updateOption('PasswordLoginEnabled', 'false');
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        }
                        <Form.Checkbox
                            checked={inputs.PasswordRegisterEnabled === 'true'}
                            label='Allow registration via password'
                            name='PasswordRegisterEnabled'
                            onChange={handleInputChange}
                        />
                        <Form.Checkbox
                            checked={inputs.EmailVerificationEnabled === 'true'}
                            label='Email verification is required when registering via password'
                            name='EmailVerificationEnabled'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.RegisterEnabled === 'true'}
                            label='Allow new user registration'
                            name='RegisterEnabled'
                            onChange={handleInputChange}
                        />
                        <Form.Checkbox
                            checked={inputs.TurnstileCheckEnabled === 'true'}
                            label='Enable Turnstile user verification'
                            name='TurnstileCheckEnabled'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Divider/>
                    <Header as='h3'>
                        Configure email domain name whitelist
                        <Header.Subheader>To prevent malicious users from using temporary email addresses to register in batches</Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Checkbox
                            label='Enable email domain name whitelist'
                            name='EmailDomainRestrictionEnabled'
                            onChange={handleInputChange}
                            checked={inputs.EmailDomainRestrictionEnabled === 'true'}
                        />
                    </Form.Group>
                    <Form.Group widths={2}>
                        <Form.Dropdown
                            label='Allowed email domain names'
                            placeholder='Allowed email domain names'
                            name='EmailDomainWhitelist'
                            required
                            fluid
                            multiple
                            selection
                            onChange={handleInputChange}
                            value={inputs.EmailDomainWhitelist}
                            autoComplete='new-password'
                            options={EmailDomainWhitelist}
                        />
                        <Form.Input
                            label='Add new allowed email domain name'
                            action={
                                <Button type='button' onClick={() => {
                                    submitNewRestrictedDomain();
                                }}>fill in</Button>
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    submitNewRestrictedDomain();
                                }
                            }}
                            autoComplete='new-password'
                            placeholder='Enter new allowed email domain name'
                            value={restrictedDomainInput}
                            onChange={(e, {value}) => {
                                setRestrictedDomainInput(value);
                            }}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitEmailDomainWhitelist}>Save email domain name whitelist settings</Form.Button>
                    <Divider/>
                    <Header as='h3'>
                        Configure SMTP
                        <Header.Subheader>To support the system email sending</Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Input
                            label='SMTP Server Address'
                            name='SMTPServer'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPServer}
                            placeholder='For example: smtp.devsolux.net'
                        />
                        <Form.Input
                            label='SMTP Port'
                            name='SMTPPort'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPPort}
                            placeholder='Default: 587'
                        />
                        <Form.Input
                            label='SMTP Account'
                            name='SMTPAccount'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPAccount}
                            placeholder='Usually an email address'
                        />
                    </Form.Group>
                    <Form.Group widths={3}>
                        <Form.Input
                            label='SMTP From'
                            name='SMTPFrom'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPFrom}
                            placeholder='Usually consistent with the email address'
                        />
                        <Form.Input
                            label='SMTP Token'
                            name='SMTPToken'
                            onChange={handleInputChange}
                            type='password'
                            autoComplete='new-password'
                            checked={inputs.RegisterEnabled === 'true'}
                            placeholder='SMTP Token'
                        />
                    </Form.Group>
                    <Form.Button onClick={submitSMTP}>Save SMTP Settings</Form.Button>
                    <Divider />
                    <Header as='h3'>
                        Configure Turnstile
                        <Header.Subheader>
                            To support user verification，Manage your Turnstile Sites, recommend selecting Invisible Widget Type:
                            <a href='https://dash.cloudflare.com/' target='_blank'>
                                Click here
                            </a>
                        </Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Input
                            label='Turnstile Site Key'
                            name='TurnstileSiteKey'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.TurnstileSiteKey}
                            placeholder='Enter your registered Turnstile Site Key'
                        />
                        <Form.Input
                            label='Turnstile Secret Key'
                            name='TurnstileSecretKey'
                            onChange={handleInputChange}
                            type='password'
                            autoComplete='new-password'
                            value={inputs.TurnstileSecretKey}
                            placeholder='Turnstile Secret Key'
                        />
                    </Form.Group>
                    <Form.Button onClick={submitTurnstile}>
                        Save Turnstile Settings
                    </Form.Button>
                </Form>
            </Grid.Column>
        </Grid>
    );
};

export default SystemSetting;
