import React, {useEffect, useState, useCallback} from 'react';
import {Button, Form, Label, Pagination, Popup, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {API, showError, showSuccess} from '../helpers';

import {ITEMS_PER_PAGE} from '../constants';
import {renderGroup, renderNumber, renderQuota, renderText} from '../helpers/render';

function renderRole(role) {
    switch (role) {
        case 1:
            return <Label>Regular User</Label>;
        case 10:
            return <Label color='yellow'>Admin</Label>;
        case 100:
            return <Label color='orange'>Super Admin</Label>;
        default:
            return <Label color='red'>Unknown Identity</Label>;
    }
}

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searching, setSearching] = useState(false);

    const loadUsers = useCallback(async (startIdx) => {
        const res = await API.get(`/api/user/?p=${startIdx}`);
        const {success, message, data} = res.data;
        if (success) {
            if (startIdx === 0) {
                setUsers(data);
            } else {
                let newUsers = users;
                newUsers.push(...data);
                setUsers(newUsers);
            }
        } else {
            showError(message);
        }
        setLoading(false);
    }, []);

    const onPaginationChange = (e, {activePage}) => {
        (async () => {
            if (activePage === Math.ceil(users.length / ITEMS_PER_PAGE) + 1) {
                // In this case we have to load more data and then append them.
                await loadUsers(activePage - 1);
            }
            setActivePage(activePage);
        })();
    };

    useEffect(() => {
        loadUsers(0)
            .then()
            .catch((reason) => {
                showError(reason);
            });
    }, [loadUsers]);

    const manageUser = (username, action, idx) => {
        (async () => {
            const res = await API.post('/api/user/manage', {
                username,
                action
            });
            const {success, message} = res.data;
            if (success) {
                showSuccess('Operation completed successfully!');
                let user = res.data.data;
                let newUsers = [...users];
                let realIdx = (activePage - 1) * ITEMS_PER_PAGE + idx;
                if (action === 'delete') {
                    newUsers[realIdx].deleted = true;
                } else {
                    newUsers[realIdx].status = user.status;
                    newUsers[realIdx].role = user.role;
                }
                setUsers(newUsers);
            } else {
                showError(message);
            }
        })();
    };

    const renderStatus = (status) => {
        switch (status) {
            case 1:
                return <Label basic>Activated</Label>;
            case 2:
                return (
                    <Label basic color='red'>
                        Banned
                    </Label>
                );
            default:
                return (
                    <Label basic color='grey'>
                        Unknown status
                    </Label>
                );
        }
    };

    const searchUsers = async () => {
        if (searchKeyword === '') {
            // if keyword is blank, load files instead.
            await loadUsers(0);
            setActivePage(1);
            return;
        }
        setSearching(true);
        const res = await API.get(`/api/user/search?keyword=${searchKeyword}`);
        const {success, message, data} = res.data;
        if (success) {
            setUsers(data);
            setActivePage(1);
        } else {
            showError(message);
        }
        setSearching(false);
    };

    const handleKeywordChange = async (e, {value}) => {
        setSearchKeyword(value.trim());
    };

    const sortUser = (key) => {
        if (users.length === 0) return;
        setLoading(true);
        let sortedUsers = [...users];
        sortedUsers.sort((a, b) => {
            if (!isNaN(a[key])) {
                // If the value is numeric, subtract to sort
                return a[key] - b[key];
            } else {
                // If the value is not numeric, sort as strings
                return ('' + a[key]).localeCompare(b[key]);
            }
        });
        if (sortedUsers[0].id === users[0].id) {
            sortedUsers.reverse();
        }
        setUsers(sortedUsers);
        setLoading(false);
    };

    return (
        <>
            <Form onSubmit={searchUsers}>
                <Form.Input
                    icon='search'
                    fluid
                    iconPosition='left'
                    placeholder='Search user ID, username, display name, and email address...'
                    value={searchKeyword}
                    loading={searching}
                    onChange={handleKeywordChange}
                />
            </Form>

            <Table basic compact size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                sortUser('id');
                            }}
                        >
                            ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                sortUser('username');
                            }}
                        >
                            Username
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                sortUser('group');
                            }}
                        >
                            Group
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                sortUser('quota');
                            }}
                        >
                            Statistics
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                sortUser('role');
                            }}
                        >
                            User Role
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                sortUser('status');
                            }}
                        >
                            Status
                        </Table.HeaderCell>
                        <Table.HeaderCell>Operation</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {users
                        .slice(
                            (activePage - 1) * ITEMS_PER_PAGE,
                            activePage * ITEMS_PER_PAGE
                        )
                        .map((user, idx) => {
                            if (user.deleted) return <></>;
                            return (
                                <Table.Row key={user.id}>
                                    <Table.Cell>{user.id}</Table.Cell>
                                    <Table.Cell>
                                        <Popup
                                            content={user.email ? user.email : 'Email not bound'}
                                            key={user.username}
                                            header={user.display_name ? user.display_name : user.username}
                                            trigger={<span>{renderText(user.username, 15)}</span>}
                                            hoverable
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{renderGroup(user.group)}</Table.Cell>
                                    {/*<Table.Cell>*/}
                                    {/*  {user.email ? <Popup hoverable content={user.email} trigger={<span>{renderText(user.email, 24)}</span>} /> : 'None'}*/}
                                    {/*</Table.Cell>*/}
                                    <Table.Cell>
                                        <Popup content='Remaining quota' trigger={<Label basic>{renderQuota(user.quota)}</Label>}/>
                                        <Popup content='Quota used' trigger={<Label basic>{renderQuota(user.used_quota)}</Label>}/>
                                        <Popup content='Number of Requests' trigger={<Label basic>{renderNumber(user.request_count)}</Label>}/>
                                    </Table.Cell>
                                    <Table.Cell>{renderRole(user.role)}</Table.Cell>
                                    <Table.Cell>{renderStatus(user.status)}</Table.Cell>
                                    <Table.Cell>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                            <Button
                                                size={'small'}
                                                positive
                                                onClick={() => {
                                                    manageUser(user.username, 'promote', idx);
                                                }}
                                                disabled={user.role === 100}
                                            >
                                                Promote
                                            </Button>
                                            <Button
                                                size={'small'}
                                                color={'yellow'}
                                                onClick={() => {
                                                    manageUser(user.username, 'demote', idx);
                                                }}
                                                disabled={user.role === 100}
                                            >
                                                Demote
                                            </Button>
                                            <Popup
                                                trigger={
                                                    <Button size='small' negative disabled={user.role === 100}>
                                                        Delete
                                                    </Button>
                                                }
                                                on='click'
                                                flowing
                                                hoverable
                                            >
                                                <Button
                                                    negative
                                                    onClick={() => {
                                                        manageUser(user.username, 'delete', idx);
                                                    }}
                                                >
                                                    Delete User {user.username}
                                                </Button>
                                            </Popup>
                                            <Button
                                                size={'small'}
                                                onClick={() => {
                                                    manageUser(
                                                        user.username,
                                                        user.status === 1 ? 'disable' : 'enable',
                                                        idx
                                                    );
                                                }}
                                                disabled={user.role === 100}
                                            >
                                                {user.status === 1 ? 'Disable' : 'Enable'}
                                            </Button>
                                            <Button
                                                size={'small'}
                                                as={Link}
                                                to={'/user/edit/' + user.id}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='7'>
                            <Button size='small' as={Link} to='/user/add' loading={loading}>
                                Add New User
                            </Button>
                            <Pagination
                                floated='right'
                                activePage={activePage}
                                onPageChange={onPaginationChange}
                                size='small'
                                siblingRange={1}
                                totalPages={
                                    Math.ceil(users.length / ITEMS_PER_PAGE) +
                                    (users.length % ITEMS_PER_PAGE === 0 ? 1 : 0)
                                }
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
};

export default UsersTable;
