import React from 'react';
import {Container, Segment} from 'semantic-ui-react';

const Footer = () => {
    return (
        <Segment vertical>
            <Container textAlign='center'>
                <div className='custom-footer'>
                    © {new Date().getFullYear()} DevSolux. Powered by Baris Aydin
                </div>
            </Container>
        </Segment>
    );
};

export default Footer;
