export const reducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                status: action.payload,
            };
        case 'unset':
            return {
                ...state,
                status: undefined,
            };
        default:
            return state;
    }
};

export const initialState = {
    status: {
        version: '',
        start_time: '',
        email_verification: '',
        server_address: '',
        turnstile_check: '',
        turnstile_site_key: '',
        top_up_link: '',
        chat_link: '',
        quota_per_unit: '',
        display_in_currency: '',
        logo: '',
        system_name: '',
        privacy_policy: '',
    },
};
