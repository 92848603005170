import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Image, Message, Segment} from 'semantic-ui-react';
import {Link, useNavigate} from 'react-router-dom';
import {API, getLogo, showError, showInfo, showSuccess} from '../helpers';
import Turnstile from 'react-turnstile';

const RegisterForm = () => {
    const [inputs, setInputs] = useState({
        username: '',
        password: '',
        password2: '',
        email: '',
        verification_code: ''
    });
    const {username, password, password2} = inputs;
    const [showEmailVerification, setShowEmailVerification] = useState(false);
    const [turnstileEnabled, setTurnstileEnabled] = useState(false);
    const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
    const [turnstileToken, setTurnstileToken] = useState('');
    const [loading, setLoading] = useState(false);
    const logo = getLogo();
    let affCode = new URLSearchParams(window.location.search).get('aff');
    if (affCode) {
        localStorage.setItem('aff', affCode);
    }

    useEffect(() => {
        let status = localStorage.getItem('status');
        if (status) {
            status = JSON.parse(status);
            if (status) {
                setShowEmailVerification(status.email_verification);
                if (status.turnstile_check) {
                    setTurnstileEnabled(true);
                    setTurnstileSiteKey(status.turnstile_site_key);
                }
            }
        }
    }, []);

    const navigate = useNavigate();

    function handleChange(e) {
        const {name, value} = e.target;
        // console.log(name, value);
        setInputs((inputs) => ({...inputs, [name]: value}));
    }

    async function handleSubmit(e) {
        if (password.length < 6) {
            showInfo('Password length must not be less than 6 character!');
            return;
        }
        if (password.length > 50) {
            showInfo('Password length must not be greater than 50 character!');
            return;
        }
        if (password !== password2) {
            showInfo('The two passwords entered do not match');
            return;
        }
        if (username && password) {
            if (turnstileEnabled && turnstileToken === '') {
                showInfo('Please retry in a few seconds, Turnstile is checking user environment!');
                return;
            }
            setLoading(true);
            if (!affCode) {
                affCode = localStorage.getItem('aff');
            }
            inputs.aff_code = affCode;
            const res = await API.post(
                `/api/user/register?turnstile=${turnstileToken}`,
                inputs
            );
            const {success, message} = res.data;
            if (success) {
                navigate('/login');
                showSuccess('Registration succeeded!');
            } else {
                showError(message);
            }
            setLoading(false);
        }
    }

    const sendVerificationCode = async () => {
        if (inputs.email === '') {
            return;
        }
        if (turnstileEnabled && turnstileToken === '') {
            showInfo('Please retry in a few seconds, Turnstile is checking user environment!');
            return;
        }
        setLoading(true);
        const res = await API.get(
            `/api/email/verification?email=${inputs.email}&turnstile=${turnstileToken}`
        );
        const {success, message} = res.data;
        if (success) {
            showSuccess('Verification code sent successfully, please check your email!');
        } else {
            showError(message);
        }
        setLoading(false);
    };

    return (
        <Grid textAlign='center' style={{marginTop: '48px'}}>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='' textAlign='center'>
                    <Image src={logo}/> New User Registration
                </Header>
                <Form size='large'>
                    <Segment>
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='Enter Username'
                            onChange={handleChange}
                            name='username'
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Enter Password'
                            onChange={handleChange}
                            name='password'
                            type='password'
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Enter Password again'
                            onChange={handleChange}
                            name='password2'
                            type='password'
                        />
                        {showEmailVerification ? (
                            <>
                                <Form.Input
                                    fluid
                                    icon='mail'
                                    iconPosition='left'
                                    placeholder='Enter Email Address'
                                    onChange={handleChange}
                                    name='email'
                                    type='email'
                                    action={
                                        <Button onClick={sendVerificationCode} disabled={loading}>
                                            Get Verification Code
                                        </Button>
                                    }
                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Enter Verification Code'
                                    onChange={handleChange}
                                    name='verification_code'
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        {turnstileEnabled ? (
                            <Turnstile
                                sitekey={turnstileSiteKey}
                                onVerify={(token) => {
                                    setTurnstileToken(token);
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <Button
                            color='green'
                            fluid
                            size='large'
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            Register
                        </Button>
                    </Segment>
                </Form>
                <Message>
                    Already have an account？
                    <Link to='/login' className='btn btn-link'>
                        Click to log in
                    </Link>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default RegisterForm;
