import React, {lazy, Suspense, useContext, useEffect, useCallback} from 'react';
import {Route, Routes} from 'react-router-dom';
import Loading from './components/Loading';
import User from './pages/User';
import {PrivateRoute} from './components/PrivateRoute';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import NotFound from './pages/NotFound';
import Setting from './pages/Setting';
import EditUser from './pages/User/EditUser';
import AddUser from './pages/User/AddUser';
import {API, getLogo, getSystemName} from './helpers';
import PasswordResetForm from './components/PasswordResetForm';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import {UserContext} from './context/User';
import {StatusContext} from './context/Status';
import Channel from './pages/Channel';
import Token from './pages/Token';
import EditToken from './pages/Token/EditToken';
import EditChannel from './pages/Channel/EditChannel';
import Redemption from './pages/Redemption';
import EditRedemption from './pages/Redemption/EditRedemption';
import TopUp from './pages/TopUp';
import Log from './pages/Log';
import Chat from './pages/Chat';

const Home = lazy(() => import('./pages/Home'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));

function App() {
    const [userState, userDispatch] = useContext(UserContext);
    const [statusState, statusDispatch] = useContext(StatusContext);

    const loadUser = useCallback(() => {
        const user = localStorage.getItem('user');
        if (user) {
            const data = JSON.parse(user);
            if (data) {
                userDispatch({type: 'login', payload: data});
            }
        }
    }, [userDispatch]);

    const loadStatus = useCallback(async () => {
        const res = await API.get('/api/status');
        if (!res || !res.data) {
            return;
        }
        const {success, data} = res.data;
        if (success) {
            localStorage.setItem('status', JSON.stringify(data));
            statusDispatch({type: 'set', payload: data});
            localStorage.setItem('system_name', data.system_name);
            localStorage.setItem('logo', data.logo);
            localStorage.setItem('privacy_policy', data.privacy_policy);
            localStorage.setItem('quota_per_unit', data.quota_per_unit);
            localStorage.setItem('display_in_currency', data.display_in_currency);
            if (data && data.chat_link) {
                localStorage.setItem('chat_link', data.chat_link);
            } else {
                localStorage.removeItem('chat_link');
            }
        }
    }, [statusDispatch]);

    const loadConfig = useCallback(async () => {
        const systemName = getSystemName();
        if (systemName) {
            document.title = systemName;
        }
        const logo = getLogo();
        if (logo) {
            let linkElement = document.querySelector("link[rel~='icon']");
            if (linkElement) {
                linkElement.href = logo;
            }
        }
    }, []);

    useEffect(() => {
        loadStatus().then(() => {
            loadConfig().then();
            loadUser();
        });
    }, [loadStatus, loadConfig, loadUser]);

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <Home/>
                    </Suspense>
                }
            />
            <Route
                path='/channel'
                element={
                    <PrivateRoute>
                        <Channel/>
                    </PrivateRoute>
                }
            />
            <Route
                path='/channel/edit/:id'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditChannel/>
                    </Suspense>
                }
            />
            <Route
                path='/channel/add'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditChannel/>
                    </Suspense>
                }
            />
            <Route
                path='/token'
                element={
                    <PrivateRoute>
                        <Token/>
                    </PrivateRoute>
                }
            />
            <Route
                path='/token/edit/:id'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditToken/>
                    </Suspense>
                }
            />
            <Route
                path='/token/add'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditToken/>
                    </Suspense>
                }
            />
            <Route
                path='/redemption'
                element={
                    <PrivateRoute>
                        <Redemption/>
                    </PrivateRoute>
                }
            />
            <Route
                path='/redemption/edit/:id'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditRedemption/>
                    </Suspense>
                }
            />
            <Route
                path='/redemption/add'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditRedemption/>
                    </Suspense>
                }
            />
            <Route
                path='/user'
                element={
                    <PrivateRoute>
                        <User/>
                    </PrivateRoute>
                }
            />
            <Route
                path='/user/edit/:id'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditUser/>
                    </Suspense>
                }
            />
            <Route
                path='/user/edit'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <EditUser/>
                    </Suspense>
                }
            />
            <Route
                path='/user/add'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <AddUser/>
                    </Suspense>
                }
            />
            <Route
                path='/user/reset'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <PasswordResetConfirm/>
                    </Suspense>
                }
            />
            <Route
                path='/login'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <LoginForm/>
                    </Suspense>
                }
            />
            <Route
                path='/register'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <RegisterForm/>
                    </Suspense>
                }
            />
            <Route
                path='/reset'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <PasswordResetForm/>
                    </Suspense>
                }
            />
            <Route
                path='/setting'
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading></Loading>}>
                            <Setting/>
                        </Suspense>
                    </PrivateRoute>
                }
            />
            <Route
                path='/topup'
                element={
                    <PrivateRoute>
                        <Suspense fallback={<Loading></Loading>}>
                            <TopUp/>
                        </Suspense>
                    </PrivateRoute>
                }
            />
            <Route
                path='/log'
                element={
                    <PrivateRoute>
                        <Log/>
                    </PrivateRoute>
                }
            />
            <Route
                path='/privacy-policy'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <PrivacyPolicy/>
                    </Suspense>
                }
            />
            <Route
                path='/chat'
                element={
                    <Suspense fallback={<Loading></Loading>}>
                        <Chat/>
                    </Suspense>
                }
            />
            <Route path='*' element={
                <NotFound/>
            }/>
        </Routes>
    );
}

export default App;
