import {toast} from 'react-toastify';
import {toastConstants} from '../constants';
import React from 'react';
import {API} from './api';

const HTMLToastContent = ({htmlContent}) => {
    return <div dangerouslySetInnerHTML={{__html: htmlContent}}/>;
}

export function isAdmin() {
    let user = localStorage.getItem('user');
    if (!user) {
        return false;
    }
    user = JSON.parse(user);
    return user.role >= 10;
}

export function isRoot() {
    let user = localStorage.getItem('user');
    if (!user) {
        return false;
    }
    user = JSON.parse(user);
    return user.role >= 100;
}

export function getSystemName() {
    const system_name = localStorage.getItem('system_name');
    if (!system_name) {
        return 'AiAPI';
    }
    return system_name;
}

export function getLogo() {
    const logo = localStorage.getItem('logo');
    if (!logo) {
        return '/logo.png';
    }
    return logo
}

export async function copy(text) {
    let okay = true;
    try {
        await navigator.clipboard.writeText(text);
    } catch (e) {
        okay = false;
        console.error(e);
    }
    return okay;
}

export function isMobile() {
    return window.innerWidth <= 600;
}

const toastOptions = {
    position: "bottom-right",
    hideProgressBar: false,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

let showErrorOptions = {...toastOptions, autoClose: toastConstants.ERROR_TIMEOUT};
let showWarningOptions = {...toastOptions, autoClose: toastConstants.WARNING_TIMEOUT};
let showSuccessOptions = {...toastOptions, autoClose: toastConstants.SUCCESS_TIMEOUT};
let showInfoOptions = {...toastOptions, autoClose: toastConstants.INFO_TIMEOUT};
let showNoticeOptions = {...toastOptions, autoClose: false};

if (isMobile()) {
    showErrorOptions.position = 'top-center';
    // showErrorOptions.transition = 'flip';

    showSuccessOptions.position = 'top-center';
    // showSuccessOptions.transition = 'flip';

    showInfoOptions.position = 'top-center';
    // showInfoOptions.transition = 'flip';

    showNoticeOptions.position = 'top-center';
    // showNoticeOptions.transition = 'flip';
}

export function showError(error) {
    if (!error) {
        return;
    }

    setTimeout(() => {
        toast.dismiss();

        if (typeof error === 'string' || error instanceof String) {
            toast.error('Error: ' + error, showErrorOptions);
        } else {
            if (error && error.message) {
                if (error.name === 'AxiosError') {
                    const status = error.response && error.response.status ? error.response.status : 200;
                    switch (status) {
                        case 401:
                            // toast.error('Error: Not logged in or login has expired, please log in again!', showErrorOptions);
                            window.location.href = '/login?expired=true';
                            break;
                        case 429:
                            toast.error('Error: Too many requests, please try again later!', showErrorOptions);
                            break;
                        case 500:
                            toast.error('Error: Server internal error, please contact the administrator!', showErrorOptions);
                            break;
                        case 405:
                            toast.info('This site is for demonstration purposes only, no server-side!');
                            break;
                        default:
                            toast.error('Error: ' + error.message, showErrorOptions);
                    }
                    return;
                }
                toast.error('Error: ' + error.message, showErrorOptions);
            }
        }
    }, 1000);
}

export function showWarning(message) {
    toast.warn(message, showWarningOptions);
}

export function showSuccess(message) {
    toast.success(message, showSuccessOptions);
}

export function showInfo(message) {
    toast.info(message, showInfoOptions);
}

export function showNotice(message, isHTML = false) {
    if (isHTML) {
        toast(<HTMLToastContent htmlContent={message}/>, showNoticeOptions);
    } else {
        toast.info(message, showNoticeOptions);
    }
}

export function removeTrailingSlash(url) {
    if (url.endsWith('/')) {
        return url.slice(0, -1);
    } else {
        return url;
    }
}

export function timestamp2string(timestamp) {
    let date = new Date(timestamp * 1000);
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();
    let second = date.getSeconds().toString();
    if (month.length === 1) {
        month = '0' + month;
    }
    if (day.length === 1) {
        day = '0' + day;
    }
    if (hour.length === 1) {
        hour = '0' + hour;
    }
    if (minute.length === 1) {
        minute = '0' + minute;
    }
    if (second.length === 1) {
        second = '0' + second;
    }
    return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
    );
}

export function downloadTextAsFile(text, filename) {
    let blob = new Blob([text], {type: 'text/plain;charset=utf-8'});
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
}

export const verifyJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export function shouldShowPrompt(id) {
    let prompt = localStorage.getItem(`prompt-${id}`);
    return !prompt;

}

export function setPromptShown(id) {
    localStorage.setItem(`prompt-${id}`, 'true');
}

let channelModels = undefined;

export async function loadChannelModels() {
    const res = await API.get('/api/models');
    const {success, data} = res.data;
    if (!success) {
        return;
    }
    channelModels = data;
    localStorage.setItem('channel_models', JSON.stringify(data));
}

export function getChannelModels(type) {
    if (channelModels !== undefined && type in channelModels) {
        return channelModels[type];
    }
    let models = localStorage.getItem('channel_models');
    if (!models) {
        return [];
    }
    channelModels = JSON.parse(models);
    if (type in channelModels) {
        return channelModels[type];
    }
    return [];
}