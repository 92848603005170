import React, {useEffect, useState, useCallback} from 'react';
import {Button, Form, Grid, Header, Segment, Statistic} from 'semantic-ui-react';
import {API, showError, showInfo, showSuccess} from '../../helpers';
import {renderQuota} from '../../helpers/render';

const TopUp = () => {
    const [redemptionCode, setRedemptionCode] = useState('');
    const [topUpLink, setTopUpLink] = useState('');
    const [userQuota, setUserQuota] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const topUp = async () => {
        if (redemptionCode === '') {
            showInfo('Enter the recharge code!')
            return;
        }
        setIsSubmitting(true);
        try {
            const res = await API.post('/api/user/topup', {
                key: redemptionCode
            });
            const {success, message, data} = res.data;
            if (success) {
                showSuccess('Recharge successful!');
                setUserQuota((quota) => {
                    return quota + data;
                });
                setRedemptionCode('');
            } else {
                showError(message);
            }
        } catch (err) {
            showError('Request failed');
        } finally {
            setIsSubmitting(false);
        }
    };

    const openTopUpLink = () => {
        if (!topUpLink) {
            showError('Super administrator has not set the recharge link!');
            return;
        }
        window.open(topUpLink, '_blank');
    };

    const getUserQuota = useCallback(async () => {
        let res = await API.get(`/api/user/self`);
        if (!res || !res.data) {
            return;
        }
        const {success, message, data} = res.data;
        if (success) {
            setUserQuota(data.quota);
        } else {
            showError(message);
        }
    }, []);

    useEffect(() => {
        let status = localStorage.getItem('status');
        if (status) {
            status = JSON.parse(status);
            if (status && status.top_up_link) {
                setTopUpLink(status.top_up_link);
            }
        }
        getUserQuota().then();
    }, [getUserQuota]);

    return (
        <Segment>
            <Header as='h3'>Recharge Quota</Header>
            <Grid columns={2} stackable>
                <Grid.Column>
                    <Form>
                        <Form.Input
                            placeholder='Redeem Code'
                            name='redemptionCode'
                            value={redemptionCode}
                            onChange={(e) => {
                                setRedemptionCode(e.target.value);
                            }}
                        />
                        <Button color='green' onClick={openTopUpLink}>
                            Get Redeem Code
                        </Button>
                        <Button color='yellow' onClick={topUp} disabled={isSubmitting}>
                            {isSubmitting ? 'Redeeming...' : 'Redeem'}
                        </Button>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Statistic.Group widths='one'>
                        <Statistic>
                            <Statistic.Value>{renderQuota(userQuota)}</Statistic.Value>
                            <Statistic.Label>Remaining quota</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default TopUp;