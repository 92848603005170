import React, {useEffect, useState, useCallback} from 'react';
import {Button, Form, Header, Segment} from 'semantic-ui-react';
import {useParams, useNavigate} from 'react-router-dom';
import {API, showError, showSuccess} from '../../helpers';
import {renderQuotaWithPrompt} from '../../helpers/render';

const EditUser = () => {
    const params = useParams();
    const userId = params.id;
    const [loading, setLoading] = useState(true);
    const [inputs, setInputs] = useState({
        username: '',
        display_name: '',
        password: '',
        email: '',
        quota: 0,
        group: 'default'
    });
    const [groupOptions, setGroupOptions] = useState([]);
    const {username, display_name, password, email, quota, group} = inputs;
    const handleInputChange = (e, {name, value}) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
    };
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate("/setting");
    }

    const fetchGroups = useCallback(async () => {
        try {
            let res = await API.get(`/api/group/`);
            setGroupOptions(res.data.data.map((group) => ({
                key: group,
                text: group,
                value: group,
            })));
        } catch (error) {
            showError(error.message);
        }
    }, []);

    const loadUser = useCallback(async () => {
        let res = undefined;
        if (userId) {
            res = await API.get(`/api/user/${userId}`);
        } else {
            res = await API.get(`/api/user/self`);
        }
        const {success, message, data} = res.data;
        if (success) {
            data.password = '';
            setInputs(data);
        } else {
            showError(message);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        loadUser().then(() => {
            if (userId) {
                fetchGroups().then();
            }
        });
    }, [loadUser, fetchGroups]);

    const submit = async () => {
        let res = undefined;
        if (userId) {
            let data = {...inputs, id: parseInt(userId)};
            if (typeof data.quota === 'string') {
                data.quota = parseInt(data.quota);
            }
            res = await API.put(`/api/user/`, data);
        } else {
            res = await API.put(`/api/user/self`, inputs);
        }
        const {success, message} = res.data;
        if (success) {
            showSuccess('User information updated successfully!');
        } else {
            showError(message);
        }
    };

    return (
        <>
            <Segment loading={loading}>
                <Header as='h3'>Update User Information</Header>
                <Form autoComplete='new-password'>
                    <Form.Field>
                        <Form.Input
                            label='Username'
                            name='username'
                            placeholder={'Enter a new username'}
                            onChange={handleInputChange}
                            value={username}
                            autoComplete='new-password'
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            label='Password'
                            name='password'
                            type={'password'}
                            placeholder={'Enter a new password, minimum length of 8 characters.'}
                            onChange={handleInputChange}
                            value={password}
                            autoComplete='new-password'
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            label='Display Name'
                            name='display_name'
                            placeholder={'Enter a new display name'}
                            onChange={handleInputChange}
                            value={display_name}
                            autoComplete='new-password'
                        />
                    </Form.Field>
                    {
                        userId && <>
                            <Form.Field>
                                <Form.Dropdown
                                    label='Group'
                                    placeholder={'Please select a group'}
                                    name='group'
                                    fluid
                                    search
                                    selection
                                    allowAdditions
                                    additionLabel={'Please edit the group ratio in the system settings page to add a new group:'}
                                    onChange={handleInputChange}
                                    value={inputs.group}
                                    autoComplete='new-password'
                                    options={groupOptions}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    label={`Remaining balance ${renderQuotaWithPrompt(quota)}`}
                                    name='quota'
                                    placeholder={'Enter the new remaining quota'}
                                    onChange={handleInputChange}
                                    value={quota}
                                    type={'number'}
                                    autoComplete='new-password'
                                />
                            </Form.Field>
                        </>
                    }
                    <Form.Field>
                        <Form.Input
                            label='Email Account Bound'
                            name='email'
                            value={email}
                            autoComplete='new-password'
                            placeholder='This item is read-only. Users need to bind through the relevant binding button on the personal settings page, and cannot be modified directly'
                            readOnly
                        />
                    </Form.Field>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button positive onClick={submit}>Submit</Button>
                </Form>
            </Segment>
        </>
    );
};

export default EditUser;
