import React, {useContext, useEffect, useState} from 'react';
import {Button, Divider, Form, Grid, Header, Image, Message, Modal, Segment} from 'semantic-ui-react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {UserContext} from '../context/User';
import {API, getLogo, showError, showSuccess, showWarning} from '../helpers';

const LoginForm = () => {
    const [inputs, setInputs] = useState({
        username: '',
        password: '',
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [submitted, setSubmitted] = useState(false);
    const {username, password} = inputs;
    const [userState, userDispatch] = useContext(UserContext);
    const navigate = useNavigate();
    const [status, setStatus] = useState({});
    const logo = getLogo();

    useEffect(() => {
        if (searchParams.get('expired')) {
            showError('Not logged in or login has expired, please log in again!');
        }
        let status = localStorage.getItem('status');
        if (status) {
            status = JSON.parse(status);
            setStatus(status);
        }
    }, [searchParams]);

    function handleChange(e) {
        const {name, value} = e.target;
        setInputs((inputs) => ({...inputs, [name]: value}));
    }

    async function handleSubmit(e) {
        setSubmitted(true);
        if (username && password) {
            const res = await API.post(`/api/user/login`, {
                username,
                password
            });
            const {success, message, data} = res.data;
            if (success) {
                userDispatch({type: 'login', payload: data});
                localStorage.setItem('user', JSON.stringify(data));
                if (username === 'root' && password === '123456') {
                    navigate('/user/edit');
                    showSuccess('Login succeeded!');
                    showWarning('Please change the default password immediately!');
                } else {
                    navigate('/token');
                    showSuccess('Login succeeded!');
                }
            } else {
                showError(message);
            }
        }
    }

    return (
        <Grid textAlign='center' style={{marginTop: '48px'}}>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='' textAlign='center'>
                    <Image src={logo}/> User login
                </Header>
                <Form size='large'>
                    <Segment>
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='Username'
                            name='username'
                            value={username}
                            onChange={handleChange}
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Password'
                            name='password'
                            type='password'
                            value={password}
                            onChange={handleChange}
                        />
                        <Button color='green' fluid size='large' onClick={handleSubmit}>
                            Login
                        </Button>
                    </Segment>
                </Form>
                <Message>
                    <Message.Content>
                        <Message.Header>
                            <Link to='/reset' className='btn btn-link'>
                                Forget password?
                            </Link>
                        </Message.Header>
                        <Link to='/register' className='btn btn-link'>
                            No account? Click to register
                        </Link>
                    </Message.Content>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default LoginForm;
