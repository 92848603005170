import React, {useEffect, useState, useCallback} from 'react';
import {Button, Divider, Form, Grid, Header, Message, Modal} from 'semantic-ui-react';
import {API, showError} from '../helpers';

const OtherSetting = () => {
    let [inputs, setInputs] = useState({
        PrivacyPolicy: '',
        Notice: '',
        SystemName: '',
        Logo: '',
    });
    let [loading, setLoading] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateData, setUpdateData] = useState({
        tag_name: '',
        content: ''
    });

    const getOptions = useCallback(async () => {
        const res = await API.get('/api/option/');
        if (!res || !res.data) {
            return;
        }
        const {success, message, data} = res.data;
        if (success) {
            let newInputs = {};
            data.forEach((item) => {
                if (item.key in inputs) {
                    newInputs[item.key] = item.value;
                }
            });
            setInputs(newInputs);
        } else {
            showError(message);
        }
    }, []);

    useEffect(() => {
        getOptions().then();
    }, [getOptions]);

    const updateOption = async (key, value) => {
        setLoading(true);
        const res = await API.put('/api/option/', {
            key,
            value
        });
        const {success, message} = res.data;
        if (success) {
            setInputs((inputs) => ({...inputs, [key]: value}));
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const handleInputChange = async (e, {name, value}) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
    };

    const submitNotice = async () => {
        await updateOption('Notice', inputs.Notice);
    };

    /*
    const submitPrivacyPolicy = async () => {
        await updateOption('PrivacyPolicy', inputs.PrivacyPolicy);
    };

    const submitSystemName = async () => {
        await updateOption('SystemName', inputs.SystemName);
    };

    const submitLogo = async () => {
        await updateOption('Logo', inputs.Logo);
    };
    */

    return (
        <Grid columns={1}>
            <Grid.Column>
                <Form loading={loading}>
                    <Header as='h3'>General Settings</Header>
                    <Form.Group widths='equal'>
                        <Form.TextArea
                            label='Notice'
                            placeholder='Enter the new notice content here, supports Markdown & HTML code'
                            value={inputs.Notice}
                            name='Notice'
                            onChange={handleInputChange}
                            style={{minHeight: 150, fontFamily: 'JetBrains Mono, Consolas'}}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitNotice}>Save Notice</Form.Button>

                    {/*
                    <Form.Group widths='equal'>
                        <Form.Input
                            label='System Name'
                            placeholder='Enter the system name here'
                            value={inputs.SystemName}
                            name='SystemName'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitSystemName}>Set system name</Form.Button>

                    <Form.Group widths='equal'>
                        <Form.Input
                            label='Logo Image URL'
                            placeholder='Enter the Logo image URL here'
                            value={inputs.Logo}
                            name='Logo'
                            type='url'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitLogo}>Settings Logo</Form.Button>

                    <Form.Group widths='equal'>
                        <Form.TextArea
                            label='PrivacyPolicy'
                            placeholder='Enter the new privacy policy content here, supports HTML code'
                            value={inputs.PrivacyPolicy}
                            name='PrivacyPolicy'
                            onChange={handleInputChange}
                            style={{minHeight: 150, fontFamily: 'JetBrains Mono, Consolas'}}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitPrivacyPolicy}>Save PrivacyPolicy</Form.Button>
                    */}
                </Form>
            </Grid.Column>
            <Modal
                onClose={() => setShowUpdateModal(false)}
                onOpen={() => setShowUpdateModal(true)}
                open={showUpdateModal}
            >
                <Modal.Header>New Version：{updateData.tag_name}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div dangerouslySetInnerHTML={{__html: updateData.content}}></div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowUpdateModal(false)}>Close</Button>
                    <Button
                        content='Details'
                        onClick={() => {
                            setShowUpdateModal(false);
                        }}
                    />
                </Modal.Actions>
            </Modal>
        </Grid>
    );
};

export default OtherSetting;
