import React, {useCallback, useEffect, useState} from 'react';
import {Divider, Form, Grid, Header} from 'semantic-ui-react';
import {API, showError, showSuccess, timestamp2string, verifyJSON} from '../helpers';

const OperationSetting = () => {
    let now = new Date();
    let [inputs, setInputs] = useState({
        QuotaForNewUser: 0,
        QuotaForInviter: 0,
        QuotaForInvitee: 0,
        QuotaRemindThreshold: 0,
        PreConsumedQuota: 0,
        ModelRatio: '',
        CompletionRatio: '',
        GroupRatio: '',
        TopUpLink: '',
        ChatLink: '',
        QuotaPerUnit: 0,
        AutomaticDisableChannelEnabled: '',
        AutomaticEnableChannelEnabled: '',
        ChannelDisableThreshold: 0,
        LogConsumeEnabled: '',
        DisplayInCurrencyEnabled: '',
        DisplayTokenStatEnabled: '',
        ApproximateTokenEnabled: '',
        RetryTimes: 0
    });
    const [originInputs, setOriginInputs] = useState({});
    let [loading, setLoading] = useState(false);
    let [historyTimestamp, setHistoryTimestamp] = useState(timestamp2string(now.getTime() / 1000 - 30 * 24 * 3600)); // a month ago

    const getOptions = useCallback(async () => {
        const res = await API.get('/api/option/');
        const {success, message, data} = res.data;
        if (success) {
            let newInputs = {};
            data.forEach((item) => {
                if (item.key === 'ModelRatio' || item.key === 'GroupRatio' || item.key === 'CompletionRatio') {
                    item.value = JSON.stringify(JSON.parse(item.value), null, 2);
                }
                newInputs[item.key] = item.value;
            });
            setInputs(newInputs);
            setOriginInputs(newInputs);
        } else {
            showError(message);
        }
    }, []);

    useEffect(() => {
        getOptions().then();
    }, [getOptions]);

    const updateOption = async (key, value) => {
        setLoading(true);
        if (key.endsWith('Enabled')) {
            value = inputs[key] === 'true' ? 'false' : 'true';
        }
        const res = await API.put('/api/option/', {
            key,
            value
        });
        const {success, message} = res.data;
        if (success) {
            setInputs((inputs) => ({...inputs, [key]: value}));
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const handleInputChange = async (e, {name, value}) => {
        if (name.endsWith('Enabled')) {
            await updateOption(name, value);
        } else {
            setInputs((inputs) => ({...inputs, [name]: value}));
        }
    };

    const submitConfig = async (group) => {
        switch (group) {
            case 'monitor':
                if (originInputs['ChannelDisableThreshold'] !== inputs.ChannelDisableThreshold) {
                    await updateOption('ChannelDisableThreshold', inputs.ChannelDisableThreshold);
                }
                if (originInputs['QuotaRemindThreshold'] !== inputs.QuotaRemindThreshold) {
                    await updateOption('QuotaRemindThreshold', inputs.QuotaRemindThreshold);
                }
                break;
            case 'ratio':
                if (originInputs['ModelRatio'] !== inputs.ModelRatio) {
                    if (!verifyJSON(inputs.ModelRatio)) {
                        showError('Model rate is not a valid JSON string');
                        return;
                    }
                    await updateOption('ModelRatio', inputs.ModelRatio);
                }
                if (originInputs['GroupRatio'] !== inputs.GroupRatio) {
                    if (!verifyJSON(inputs.GroupRatio)) {
                        showError('Group rate is not a valid JSON string');
                        return;
                    }
                    await updateOption('GroupRatio', inputs.GroupRatio);
                }
                if (originInputs['CompletionRatio'] !== inputs.CompletionRatio) {
                    if (!verifyJSON(inputs.CompletionRatio)) {
                        showError('Completion is not a valid JSON string');
                        return;
                    }
                    await updateOption('CompletionRatio', inputs.CompletionRatio);
                }
                break;
            case 'quota':
                if (originInputs['QuotaForNewUser'] !== inputs.QuotaForNewUser) {
                    await updateOption('QuotaForNewUser', inputs.QuotaForNewUser);
                }
                if (originInputs['QuotaForInvitee'] !== inputs.QuotaForInvitee) {
                    await updateOption('QuotaForInvitee', inputs.QuotaForInvitee);
                }
                if (originInputs['QuotaForInviter'] !== inputs.QuotaForInviter) {
                    await updateOption('QuotaForInviter', inputs.QuotaForInviter);
                }
                if (originInputs['PreConsumedQuota'] !== inputs.PreConsumedQuota) {
                    await updateOption('PreConsumedQuota', inputs.PreConsumedQuota);
                }
                break;
            case 'general':
                if (originInputs['TopUpLink'] !== inputs.TopUpLink) {
                    await updateOption('TopUpLink', inputs.TopUpLink);
                }
                if (originInputs['ChatLink'] !== inputs.ChatLink) {
                    await updateOption('ChatLink', inputs.ChatLink);
                }
                if (originInputs['QuotaPerUnit'] !== inputs.QuotaPerUnit) {
                    await updateOption('QuotaPerUnit', inputs.QuotaPerUnit);
                }
                if (originInputs['RetryTimes'] !== inputs.RetryTimes) {
                    await updateOption('RetryTimes', inputs.RetryTimes);
                }
                break;
        }
    };

    const deleteHistoryLogs = async () => {
        const res = await API.delete(`/api/log/?target_timestamp=${Date.parse(historyTimestamp) / 1000}`);
        const {success, message, data} = res.data;
        if (success) {
            showSuccess(`${data} Logs have been cleared!`);
            return;
        }
        showError('Log cleaning failed：' + message);
    };

    return (
        <Grid columns={1}>
            <Grid.Column>
                <Form loading={loading}>
                    <Header as='h3'>
                        General Settings
                    </Header>
                    <Form.Group widths={4}>
                        <Form.Input
                            label='Recharge Link'
                            name='TopUpLink'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.TopUpLink}
                            type='link'
                            placeholder='For example, the purchase link of the card issuing website'
                        />
                        <Form.Input
                            label='Chat Page Link'
                            name='ChatLink'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.ChatLink}
                            type='link'
                            placeholder='For example, the deployment address of AiChat'
                        />
                        <Form.Input
                            label='Unit Dollar Quota'
                            name='QuotaPerUnit'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.QuotaPerUnit}
                            type='number'
                            step='0.01'
                            placeholder='Quota that can be exchanged for one unit of currency'
                        />
                        <Form.Input
                            label='Number of failed retries'
                            name='RetryTimes'
                            type={'number'}
                            step='1'
                            min='0'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.RetryTimes}
                            placeholder='Number of failed retries'
                        />
                    </Form.Group>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.DisplayInCurrencyEnabled === 'true'}
                            label='Display quota in the form of currency'
                            name='DisplayInCurrencyEnabled'
                            onChange={handleInputChange}
                        />
                        <Form.Checkbox
                            checked={inputs.DisplayTokenStatEnabled === 'true'}
                            label='Billing Related API displays token quota instead of user quota'
                            name='DisplayTokenStatEnabled'
                            onChange={handleInputChange}
                        />
                        <Form.Checkbox
                            checked={inputs.ApproximateTokenEnabled === 'true'}
                            label='Estimate the number of tokens in an approximate way to reduce computational load'
                            name='ApproximateTokenEnabled'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Button onClick={() => {
                        submitConfig('general').then();
                    }}>Save General Settings</Form.Button>
                    <Divider/>
                    <Header as='h3'>
                        Log settings
                    </Header>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.LogConsumeEnabled === 'true'}
                            label='Enable quota consumption log recording'
                            name='LogConsumeEnabled'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group widths={4}>
                        <Form.Input label='Target time' value={historyTimestamp} type='datetime-local'
                                    name='history_timestamp'
                                    onChange={(e, {name, value}) => {
                                        setHistoryTimestamp(value);
                                    }}/>
                    </Form.Group>
                    <Form.Button onClick={() => {
                        deleteHistoryLogs().then();
                    }}>Clear history log</Form.Button>
                    <Divider/>
                    <Header as='h3'>
                        Monitoring Settings
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Input
                            label='Channel Response Time'
                            name='ChannelDisableThreshold'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.ChannelDisableThreshold}
                            type='number'
                            min='0'
                            placeholder='Unit in seconds，When all operating channels are tested，Channels will be automatically disabled if this time is exceeded'
                        />
                        <Form.Input
                            label='Quota Email Reminder'
                            name='QuotaRemindThreshold'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.QuotaRemindThreshold}
                            type='number'
                            min='0'
                            placeholder='Email will be sent to remind users when the quota is below this'
                        />
                    </Form.Group>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.AutomaticDisableChannelEnabled === 'true'}
                            label='Automatically disable the channel when it fails'
                            name='AutomaticDisableChannelEnabled'
                            onChange={handleInputChange}
                        />
                        <Form.Checkbox
                            checked={inputs.AutomaticEnableChannelEnabled === 'true'}
                            label='Automatically enable channel on success'
                            name='AutomaticEnableChannelEnabled'
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Button onClick={() => {
                        submitConfig('monitor').then();
                    }}>Save Monitoring Settings</Form.Button>
                    <Divider/>
                    <Header as='h3'>
                        Quota Settings
                    </Header>
                    <Form.Group widths={4}>
                        <Form.Input
                            label='Quota For New User'
                            name='QuotaForNewUser'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.QuotaForNewUser}
                            type='number'
                            min='0'
                            placeholder='For example：100'
                        />
                        <Form.Input
                            label='Pre Consumed Quota'
                            name='PreConsumedQuota'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.PreConsumedQuota}
                            type='number'
                            min='0'
                            placeholder='Refund more or less after the request ends'
                        />
                        <Form.Input
                            label='Quota For Inviter'
                            name='QuotaForInviter'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.QuotaForInviter}
                            type='number'
                            min='0'
                            placeholder='For example：2000'
                        />
                        <Form.Input
                            label='Quota For Invitee'
                            name='QuotaForInvitee'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.QuotaForInvitee}
                            type='number'
                            min='0'
                            placeholder='For example：1000'
                        />
                    </Form.Group>
                    <Form.Button onClick={() => {
                        submitConfig('quota').then();
                    }}>Save Quota Settings</Form.Button>
                    <Divider/>
                    <Header as='h3'>
                        Rate Settings
                    </Header>
                    <Form.Group widths='equal'>
                        <Form.TextArea
                            label='Model rate'
                            name='ModelRatio'
                            onChange={handleInputChange}
                            style={{minHeight: 250, fontFamily: 'JetBrains Mono, Consolas'}}
                            autoComplete='new-password'
                            value={inputs.ModelRatio}
                            placeholder='Is a JSON text，Key is model name，Value is the rate'
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea
                            label='Completion'
                            name='CompletionRatio'
                            onChange={handleInputChange}
                            style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
                            autoComplete='new-password'
                            value={inputs.CompletionRatio}
                            placeholder='It is a JSON text, the key is the model name, and the value is the magnification. The magnification setting here is the ratio of the model completion magnification to the prompt magnification. Use this setting to force override the internal ratio.'
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea
                            label='Group rate'
                            name='GroupRatio'
                            onChange={handleInputChange}
                            style={{minHeight: 250, fontFamily: 'JetBrains Mono, Consolas'}}
                            autoComplete='new-password'
                            value={inputs.GroupRatio}
                            placeholder='Is a JSON text，Key is group name，Value is the rate'
                        />
                    </Form.Group>
                    <Form.Button onClick={() => {
                        submitConfig('ratio').then();
                    }}>Save Rate Settings</Form.Button>
                </Form>
            </Grid.Column>
        </Grid>
    );
};

export default OperationSetting;
